import type { JobOffer } from '@core/models/job-offer/job-offer.model';
import type {
  JobOfferCategory,
  JobOfferCategoryAttributes
} from '@core/models/job-offer/job-offer-category.model.ts';
import { atom, computed } from 'nanostores';

const _jobOffersStore = atom<JobOfferCategory[]>([]);

const _selectedJobOfferStore = atom<JobOfferCategoryAttributes | null>(null);

const _updateStore = (data: JobOfferCategory[]) => {
  _jobOffersStore.set(data);
};

const _selectJobOffer = (data: JobOfferCategoryAttributes) => {
  _selectedJobOfferStore.set(data);
};

const _selectBySlug = (slug: string) => {
  const jobOffer = _jobOffersStore.get().find((item) => item.attributes.slug === slug);

  if (jobOffer) {
    _selectedJobOfferStore.set(jobOffer.attributes);
  }
};

const _jobOffersByCategory = computed(
  [_jobOffersStore, _selectedJobOfferStore],
  (jobOffers, selectedJobOfferCategory): JobOffer[] => {
    return (
      jobOffers.find((item) => item.attributes.slug === selectedJobOfferCategory?.slug)?.attributes
        .job_offers.data || []
    );
  }
);

export const JobOffersStore = {
  subscribe: _jobOffersStore.subscribe,
  jobOffersByCategory: _jobOffersByCategory,
  update: _updateStore
};

export const SelectedJobOfferStore = {
  subscribe: _selectedJobOfferStore.subscribe,
  select: _selectJobOffer,
  selectBySlug: _selectBySlug
};
