<script lang="ts">
  import CareersSection from '@components/Careers/CareersSection/CareersSection.svelte';
  import { JobOffersStore } from '@pages/store/job-offers.store.ts';
  import { isEmpty } from 'ramda';

  import PositionsCategories from './components/PositionsCategories/PositionsCategories.svelte';
  import PositionsEmptyState from './components/PositionsEmptyState/PositionsEmptyState.svelte';
  import PositionTableItem from './components/PositionTableItem/PositionTableItem.svelte';

  export let jobOfferCategories;

  const { jobOffersByCategory } = JobOffersStore;

  JobOffersStore.update(jobOfferCategories);
</script>

<CareersSection sectionCss="bg-white" floatingLabel="OPEN POSITION">
  <h2 id="open-positions" class="section-title col-span-11 leading-none">
    OPEN <br /> POSITIONS
  </h2>

  <div class="flex flex-col gap-4 lg:gap-8 col-span-11">
    <PositionsCategories />

    {#if !isEmpty($jobOffersByCategory)}
      <ul class="w-full">
        {#each $jobOffersByCategory as position, index (position)}
          <li class="w-full">
            <PositionTableItem position={position.attributes} />
          </li>
        {/each}
      </ul>
    {:else}
      <PositionsEmptyState />
    {/if}
  </div>
</CareersSection>
