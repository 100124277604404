<script lang="ts">
</script>

<div
  class="w-full border min-h-[432px] flex justify-center items-center font-source-pro mb-8 p-3 lg:p-0"
>
  <div class="flex flex-col gap-4 items-center">
    <span class="text-black-carbon text-xl lg:text-2xl uppercase text-center"
      >No open positions in this section</span
    >
    <span class="text-base text-careers-basic-secondary">Check other open positions</span>
  </div>
</div>
