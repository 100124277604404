<script lang="ts">
  import { APP_ROUTES } from '@core/const/app-routes.const.ts';
  import type { JobOfferAttributes } from '@core/models/job-offer/job-offer-attributes.schema';
  import { urlBuilder } from '@core/utils/url-builder.ts';

  export let position: JobOfferAttributes;

  let technologies = position?.technologies?.split(', ') ?? [];

  let offerUrl = urlBuilder(APP_ROUTES.careers.singleJobOffer.insert({ slug: position.slug }));
  let applyUrl = urlBuilder(APP_ROUTES.careers.apply.insert({ slug: position.slug }));
</script>

<div
  class="w-full font-source-pro hover:bg-light-cream bg-white duration-300 flex flex-col md:flex-row gap-4 group md:gap-0 items-start py-4 md:py-10 border-b border-careers-basic-secondary/10 px-10"
>
  <a href={offerUrl} class="flex-col md:flex items-start w-full offer-link gap-4">
    <div class="flex flex-col lg:w-1/2 gap-2">
      <span class="text-2xl font-semibold leading-tight group-hover:underline">
        {position?.title}
      </span>
      <span class="text-sm font-normal text-careers-basic-secondary tracking-tight">
        {#each technologies as technology}
          <span class="first-of-type:hidden">, </span>{technology}
        {/each}
      </span>
    </div>

    <div class="flex flex-col">
      <span class="text-base font-semibold leading-tight">
        {position?.salary}
      </span>
      <span class="text-sm font-normal text-careers-basic-secondary tracking-tight">
        {position?.contractType}
      </span>
      <span class="text-sm font-normal text-careers-basic-secondary tracking-tight">
        {position?.location}
      </span>
    </div>
  </a>

  <a href={applyUrl} class="c-btn btn-white md:ml-auto">Apply</a>
</div>

<style lang="postcss">
  .offer-link {
    @apply relative;
  }
</style>
