<script lang="ts">
  import type {
    JobOfferCategory,
    JobOfferCategoryAttributes
  } from '@core/models/job-offer/job-offer-category.model.ts';
  import { QueryParams } from '@core/utils/query-params.ts';
  import { JobOffersStore, SelectedJobOfferStore } from '@pages/store/job-offers.store.ts';
  import { onMount } from 'svelte';

  let sortedJobOffers: JobOfferCategory[] = [];
  let selectedCategory: string = '';

  const _paramKey = 'category';

  const _preselectCategory = () => {
    const slug = QueryParams.get(_paramKey);

    if (!slug) {
      selectedCategory = sortedJobOffers[0].attributes.slug;
    } else {
      const findJobCategoryBySlug = sortedJobOffers.find((job) => job.attributes.slug === slug);

      if (!findJobCategoryBySlug) {
        selectedCategory = sortedJobOffers[0].attributes.slug;
        return;
      }

      selectedCategory = findJobCategoryBySlug.attributes.slug;
    }

    SelectedJobOfferStore.selectBySlug(selectedCategory);
  };

  const select = (job: JobOfferCategoryAttributes) => {
    SelectedJobOfferStore.select(job);
    selectedCategory = job.slug;
    QueryParams.set(_paramKey, job.slug);
  };

  onMount(() => {
    _preselectCategory();
  });

  $: {
    sortedJobOffers = $JobOffersStore.sort((a, b) => a.attributes.order - b.attributes.order);
  }
</script>

<div class="flex w-full font-source-pro">
  <ul class="w-full flex flex-1 overflow-x-auto scrollbar-hide scroll-smooth">
    {#if $JobOffersStore.length > 0}
      {#each sortedJobOffers as positionCategory, index}
        {@const job = positionCategory.attributes}
        <li
          id={job.slug}
          class:selected={selectedCategory === job.slug}
          class="p-4 uppercase duration-300 text-sm lg:text-2xl font-medium text-careers-basic-secondary"
        >
          <button
            on:click={() => select(job)}
            class="cursor-pointer whitespace-nowrap uppercase"
            type="button"
          >
            {job.title}
          </button>
        </li>
      {/each}
    {/if}
  </ul>
</div>

<style lang="postcss">
  .selected {
    @apply border-b border-black-carbon text-black-carbon;
  }
</style>
