const _errorTemplate = (method: string) =>
  `QueryParams.${method}() is not available on server side.`;

export const QueryParams = {
  get: (key: string) => {
    if (!window) return console.error(_errorTemplate('get'));

    const params = new URLSearchParams(window.location.search);
    return params.get(key);
  },
  set: (key: string, value: string) => {
    if (!window) return console.error(_errorTemplate('set'));

    const params = new URLSearchParams(window.location.search);
    params.set(key, value);

    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
  },
  update: (key: string, value: string) => {
    if (!window) return console.error(_errorTemplate('update'));

    const params = new URLSearchParams(window.location.search);
    params.set(key, value);

    window.history.pushState({}, '', `${window.location.pathname}?${params}`);
  },
  clearAll: () => {
    if (!window) return console.error(_errorTemplate('clearAll'));

    window.history.replaceState({}, '', `${window.location.pathname}`);
  },
  remove: (key: string) => {
    if (!window) return console.error(_errorTemplate('remove'));

    const params = new URLSearchParams(window.location.search);
    params.delete(key);

    window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
  }
};
